import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/layout";
import SchemaTable from "../components/schemaTable/SchemaTable";
import ParamsTable from "../components/paramsTable/ParamsTable";
import dets from "../../dets.js";
import Uris from "../components/uris/Uris";
import Seo from '../components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Seo title="RealTime" mdxType="Seo" />
    <h1>{`RealTime`}</h1>
    <section>
  <h2 id="realtime-schema">Schema</h2>
      <p>{`  See the GTFS Realtime Reference on `}<a parentName="p" {...{
          "href": "https://gtfs.org/reference/realtime/v2/"
        }}>{`gtfs.org`}</a>{`.`}</p>
  <h2 id={dets.realTime.routes[0].id}>{dets.realTime.routes[0].title}</h2>
      <h3>{`Params`}</h3>
  <ParamsTable params={dets.realTime.routes[0].params} mdxType="ParamsTable" />
      <h4>{`Request`}</h4>
      <pre><code parentName="pre" {...{}}>{`/api/realtime/bytripid/3714818.60.10-404-e19-1.1544.I
`}</code></pre>
      <h4>{`Response`}</h4>
      <p>{`  Gets the GTFS Realtime feed with feed.results.entity filtered to only show the requested trip.`}</p>
    </section>
    <section>
  <h2 id={dets.realTime.routes[1].id}>{dets.realTime.routes[1].title}</h2>
      <h4>{`Request`}</h4>
      <pre><code parentName="pre" {...{}}>{`/api/realtime/galway
`}</code></pre>
      <h4>{`Response`}</h4>
      <p>{`  Gets the GTFS Realtime feed with feed.results.entity filtered to only show Galway City related trips.`}</p>
    </section>
    <section>
  <h2 id={dets.realTime.routes[2].id}>{dets.realTime.routes[2].title}</h2>
      <p>{`  The following properties are appended to GStopTime.`}</p>
  <SchemaTable dets={dets.realTime} mdxType="SchemaTable" />
      <h3>{`Example 1`}</h3>
      <p>{`  GET all today's GStopTimes for the requested stop with realtime data from GTFS feed appended.`}</p>
      <h4>{`Params`}</h4>
  <ParamsTable params={dets.realTime.routes[2].params} mdxType="ParamsTable" />
      <h4>{`Request`}</h4>
      <pre><code parentName="pre" {...{}}>{`/api/realtime/gstoptimes/8460B5255401/404/0
`}</code></pre>
  <details>
    <summary>
    <h4>Response</h4>
    </summary>
        <pre><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "results": {
    "date": "Sat, 12 Feb 2022 00:00:00 GMT",
    "g_stop_times": [
      {
        "trip_id": "3689839.30.10-404-e19-1.1547.O",
        "arrival_time": "07:02:15",
        "departure_time": "07:02:15",
        "stop_id": "8460B5255401",
        "stop_sequence": 20,
        "shape_dist_traveled": 6725.97,
        "service_id": "30#1",
        "shape_id": "10-404-e19-1.1547.O",
        "trip_headsign": "Oranmore (Opp Oran Town Centre) - Westside (Shopping Centre)",
        "route_id": "10-404-e19-1",
        "direction_id": "0",
        "first_stop_name": "Eyre Square, stop 524371",
        "first_stop_id": "8460B5243701",
        "last_stop_id": "8460B5255401",
        "last_stop_name": "Westside Centre, stop 525541",
        "stop_name": "Westside Centre, stop 525541",
        "stop_lon": -9.07760775113074,
        "stop_lat": 53.2761484354635,
        "rt": null,
        "rt_timestamp": "1644941030",
        "departure_delay": null,
        "arrival_delay": null,
        "rt_info": "No Realtime data available"
      },
      {
        "trip_id": "3690525.30.10-404-e19-1.1548.O",
        "arrival_time": "07:33:00",
        "departure_time": "07:33:00",
        "stop_id": "8460B5255401",
        "stop_sequence": 34,
        "shape_dist_traveled": 18831.43,
        "service_id": "30#1",
        "shape_id": "10-404-e19-1.1548.O",
        "trip_headsign": "Oranmore (Opp Oran Town Centre) - Westside (Shopping Centre)",
        "route_id": "10-404-e19-1",
        "direction_id": "0",
        "first_stop_name": "Oranmore Centre, stop 556161",
        "first_stop_id": "8470B556161",
        "last_stop_id": "8460B5255401",
        "last_stop_name": "Westside Centre, stop 525541",
        "stop_name": "Westside Centre, stop 525541",
        "stop_lon": -9.07760775113074,
        "stop_lat": 53.2761484354635,
        "rt": {
          // ... the relevant realtime feed entity
        },
        "rt_timestamp": "1644996602",
        "departure_delay": -60,
        "arrival_delay": -60,
        "rt_info": "Realtime data propogated from stop 17 in route (this is stop 34 in route)."
      }
    ]
  }
}
`}</code></pre>
  </details>
      <h3>{`Example 2`}</h3>
      <p>{`  GET GStopTimes for a stop only if realtime info exists`}</p>
      <h4>{`Params`}</h4>
  <ParamsTable params={dets.realTime.routes[3].params} mdxType="ParamsTable" />
      <h4>{`Request`}</h4>
      <pre><code parentName="pre" {...{}}>{`
/api/realtimestop/84605257301
`}</code></pre>
  <details>
    <summary>
    <h4>Response</h4>
    </summary>
        <pre><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "results": [
    {
      "trip_id": "3741545.y104h.10-404-e20-1.1547.O",
      "arrival_time": "13:36:00",
      "departure_time": "13:36:00",
      "stop_id": "84605257301",
      "stop_sequence": 33,
      "shape_dist_traveled": 17479.74,
      "service_id": "y104h",
      "shape_id": "10-404-e20-1.1547.O",
      "trip_headsign": "Oranmore (Opp Oran Town Centre) - Westside (Shopping Centre)",
      "route_id": "10-404-e20-1",
      "route_short_name": "404",
      "direction_id": "0",
      "first_stop_name": "Oranmore Centre, stop 556161",
      "first_stop_id": "8470B556161",
      "last_stop_id": "8460B5255401",
      "last_stop_name": "Westside Centre, stop 525541",
      "stop_name": "Seamus Quirke Road, stop 525731",
      "stop_lon": -9.07946923657427,
      "stop_lat": 53.274450938559,
      "rt": {
        "id": "3741545.y104h.10-404-e20-1.1547.O",
        "tripUpdate": {
          "trip": {
            "tripId": "3741545.y104h.10-404-e20-1.1547.O",
            "startTime": "12:40:00",
            "startDate": "20220322",
            "scheduleRelationship": "SCHEDULED",
            "routeId": "10-404-e20-1"
          },
          "stopTimeUpdate": [
            {
              "stopSequence": 1,
              "departure": { "delay": 0 },
              "stopId": "8470B556161",
              "scheduleRelationship": "SCHEDULED"
            }
          ]
        }
      },
      "rt_timestamp": "1647949772",
      "departure_delay": 0,
      "arrival_delay": null,
      "rt_info": "Realtime data propogated from stop 1 in route (this is stop 33 in route)."
    }
    // ... more GStopTimes
  ]
}
`}</code></pre>
  </details>
    </section>
    <section className="minHeightSection">
  <h2 id={dets.realTime.routes[4].id}>{dets.realTime.routes[4].title}</h2>
      <p>{`  Gets GStopTimes for the requested trip with realtime data from GTFS feed appended.`}</p>
      <h3>{`Params`}</h3>
  <ParamsTable params={dets.realTime.routes[4].params} mdxType="ParamsTable" />
      <h3>{`Example`}</h3>
      <h4>{`Request`}</h4>
      <pre><code parentName="pre" {...{}}>{`
/api/realtime/bytripidmatch/3714818.60.10-404-e19-1.1544.I
`}</code></pre>
  <details>
    <summary>
    <h4>Response</h4>
    </summary>
        <pre><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "results": {
    "trip": {
      "route_id": "10-401-e20-1",
      "service_id": "y104a",
      "trip_id": "3725718.y104a.10-401-e20-1.1528.I",
      "shape_id": "10-401-e20-1.1528.I",
      "trip_headsign": "Dr Mannix Rd (Rockbarton Rd) - Parkmore Industrial Estate",
      "direction_id": "1"
    },
    "g_stop_times": [
      {
        "trip_id": "3725718.y104a.10-401-e20-1.1528.I",
        "arrival_time": "09:40:00",
        "departure_time": "09:40:00",
        "stop_id": "8460B522301",
        "stop_sequence": 1,
        "shape_dist_traveled": 0,
        "service_id": "y104a",
        "shape_id": "10-401-e20-1.1528.I",
        "trip_headsign": "Dr Mannix Rd (Rockbarton Rd) - Parkmore Industrial Estate",
        "route_id": "10-401-e20-1",
        "route_short_name": "401",
        "direction_id": "1",
        "first_stop_name": "Scoil Éinde Boys NS, stop 522301",
        "first_stop_id": "8460B522301",
        "last_stop_id": "8460B555431",
        "last_stop_name": "Parkmore Road, stop 555431",
        "stop_name": "Scoil Éinde Boys NS, stop 522301",
        "stop_lon": -9.08315723300363,
        "stop_lat": 53.2643514959339,
        "rt": {
          "id": "3725718.y104a.10-401-e20-1.1528.I",
          "tripUpdate": {
            "trip": {
              "tripId": "3725718.y104a.10-401-e20-1.1528.I",
              "startTime": "09:40:00",
              "startDate": "20220320",
              "scheduleRelationship": "SCHEDULED",
              "routeId": "10-401-e20-1"
            },
            "stopTimeUpdate": [
              {
                "stopSequence": 1,
                "departure": { "delay": 0 },
                "stopId": "8460B522301",
                "scheduleRelationship": "SCHEDULED"
              }
              // ... more stopTimeUpdates
            ]
          }
        },
        "rt_timestamp": "1647771600",
        "departure_delay": 0,
        "arrival_delay": null,
        "rt_info": "Realtime data listed for this stop."
      }
      // ... other GStopTimes for this trip.
    ]
  }
}
`}</code></pre>
  </details>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      